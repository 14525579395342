import {createMuiTheme} from '@material-ui/core/styles';

export const typography = {
    fontFamily: [
        'Noto Sans CJK JP',
        'Roboto',
    ].join(','),
}

// A custom base for this app
const base = createMuiTheme({
    typography: typography,
    color: "#FFFFFF",
});


export default base;