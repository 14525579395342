import React from 'react';
import {
    makeStyles,
    Grid,
    Typography,
    Radio,
    RadioGroup,
    FormControl, FormControlLabel, MuiThemeProvider, createStyles
} from "@material-ui/core"

import {createMuiTheme} from '@material-ui/core/styles';

type RadioGroupWithBothProps = {
    label: string;
    selectedValue: string;
    values: string[];
    setSelected: React.Dispatch<React.SetStateAction<string>>;
}

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#22EDFF"
        },
    },
});


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginBottom: 8,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 16,
                marginTop: "8px",
                textAlign: "center",
                justifyItems: "center",
            }
        },
        button: {
            color: "#fff",
        },
        grid: {
            [theme.breakpoints.down('sm')]: {
                padding: "0 !important",
                margin: "4px",
            }
        },
        label: {
            "font-size": "20px",
            "font-weight": "bold",
            "text-align": "left",
            [theme.breakpoints.up('md')]: {
                paddingTop: 8,
            },
            [theme.breakpoints.down('sm')]: {
                "color": "#fff",
                textAlign: "center",
                fontSize: "16px",
            },
        },
        radio: {
            [theme.breakpoints.up('md')]: {
                paddingTop: 2,
            },
            [theme.breakpoints.down('sm')]: {
                "font-size": "12px",
                "color": "#fff",
            },
        }
    })
);

const RadioGroupWithBoth = (props: RadioGroupWithBothProps) => {
    const classes = useStyles();

    const handleChange = (value: string) => {
        props.setSelected(value);
    }

    const renderRadio = () => {
        return props.values.concat("どちらも").map((value) => {
            return (
                <FormControlLabel
                    key={value}
                    onChange={() => handleChange(value)}
                    checked={props.selectedValue === value}
                    value={value}
                    control={<Radio className={classes.button} color="primary"/>}
                    label={<Typography className={classes.radio}>{value}</Typography>}
                    labelPlacement="end"
                    className={classes.radio}
                />
            )
        })
    }

    return (
        <MuiThemeProvider theme={theme}>
            <Grid container spacing={4} className={classes.root}>
                <Grid item md={2} xs={12} className={classes.grid}>
                    <Typography className={classes.label}>{props.label}</Typography>
                </Grid>
                <Grid item md={10} xs={12} className={classes.grid}>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" name="position" defaultValue="top">
                            {renderRadio()}
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </MuiThemeProvider>
    );
}

export default RadioGroupWithBoth;
