import React from 'react';
import {
    FormGroup,
    FormControlLabel,
    Switch, makeStyles, createMuiTheme, MuiThemeProvider
} from "@material-ui/core"

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#FF3DFE"
        },
    },
    overrides: {
        MuiTypography: {
            body1: {
                fontSize: "16px"
            }
        }
    }
});

const useStyles = makeStyles({
    root: {
        alignItems: "flex-end",
        [theme.breakpoints.down('sm')]: {
            alignItems: "center",
        }
    },
});

type RGBSwitcherProps = {
    isRGB: boolean;
    handleRGB: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    label: string;
}

const RGBSwitcher = (props: RGBSwitcherProps) => {
    const classes = useStyles();

    return (
        <MuiThemeProvider theme={theme}>
            <p>
                <FormGroup className={classes.root}>
                    <FormControlLabel
                        control={<Switch color={'primary'} checked={props.isRGB} onChange={props.handleRGB} name={props.label}/>}
                        label={props.label}
                    />
                </FormGroup>
            </p>
        </MuiThemeProvider>
    );
}

export default RGBSwitcher;
