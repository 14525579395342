import React, {memo} from 'react';
import {Grid, Card, Hidden, Typography, makeStyles, createStyles} from "@material-ui/core"
import {Mousepad} from "../../definitions/Mousepad";
import theme from '../../themes/components/mousepadCard'
import {ThemeProvider} from "@material-ui/core/styles";
import LinkButton from "./LinkButton";
import LazyLoad from 'react-lazyload';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: "#333333",
            border: "5px solid #22edff",
            filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.3))",
            borderRadius: "15px"
        },
        imgWrapper: {
            [theme.breakpoints.down('sm')]: {
                justContent: "center",
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: "24px !important",
                paddingLeft: "24px !important",
            },
            paddingTop: "16px  !important",
            paddingLeft: "16px  !important",

        },
        img: {
            [theme.breakpoints.down('sm')]: {
                height: "96px",
                width: "96px",
            },
            height: "200px",
            width: "200px",
            objectFit: "cover",
            borderRadius: "10px",
            justifyContent: "center",
            alignContent: "center",
        },
        Note: {
            padding: "24px 0 0 24px !important",
            [theme.breakpoints.down('sm')]: {
                padding: "16px 8px 0 0px  !important",
            },
        },
        NoteInfo: {
          padding: "0 24px !important",
        },
        Size: {
            paddingBottom: "40px",
            [theme.breakpoints.down('sm')]: {
                paddingBottom: "16px",
            },
        },
        linkButton: {
            marginTop: "24px",
            [theme.breakpoints.down('md')]: {
                marginTop: "8px",
                paddingLeft: "12px",
                marginRight: "24px",
                marginBottom: "16px"
            }
        },
        ButtonGroup: {
            [theme.breakpoints.up('lg')]: {
                textAlign: "right",
                marginTop: "30px",
                paddingRight: "24px !important",
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: "24px",
                paddingTop: 0,
                textAlign: "center"
            }
        }
    })
);

type MousepadProps = {
    mousepad: Mousepad;
    className: string;
    width: number;
}

type linkObjectType = {
    name: string;
    link: string;
}

const MousepadCard = memo((props: MousepadProps) => {

    const classes = useStyles();

    const getLinks = () => {
        let links = [] as linkObjectType[];
        if (props.mousepad.official) {
            links.push({name: "official", link: props.mousepad.official});
        }
        if (props.mousepad.amazon) {
            links.push({name: "amazon", link: props.mousepad.amazon});
        }
        return links;
    }

    const linkButtons = () => {
        return getLinks().map((obj: linkObjectType) => {
            let map: any = {
                "amazon": "Amazon",
                "official": "公式サイト"
            }
            if (map[obj.name] !== undefined) {
                map[obj.name] = obj.name;
            }
            return (
                <LinkButton key={obj.link} link={obj.link} className={classes.linkButton} label={map[obj.name]}/>
            )
        })
    }

    return (
        <div style={{width: props.width}}>
            <ThemeProvider theme={theme}>
                <Card className={[classes.root, props.className].join(" ")} elevation={5} key={props.mousepad.key}>
                    <Grid container spacing={1} style={{flexFlow: "row wrap"}}>
                        <Grid item className={classes.imgWrapper}>
                            <LazyLoad height={200}>
                                <img className={classes.img} alt={props.mousepad.name}
                                     src={require("../../images/mousepads/" + props.mousepad.key + ".jpg").default}/>
                            </LazyLoad>
                        </Grid>
                        <Hidden smDown>
                            <Grid item md={8} lg={7} xs={8} sm={8}>
                                <div className={classes.Note}>
                                    <Typography variant={"subtitle2"}>{props.mousepad.brand}</Typography>
                                    <Typography component="h2" variant="h2">{props.mousepad.name}</Typography>
                                    <Typography align="justify" component="h5"
                                                variant={"body1"}>素材: {props.mousepad.materials}</Typography>
                                    <Typography align="justify" component="h5"
                                                variant={"body1"}>スタイル: {props.mousepad.style}</Typography>
                                    <Typography align="justify" component="h5" variant={"body1"} className={classes.Size}>
                                        横幅: {props.mousepad.width} mm /
                                        縦幅: {props.mousepad.height} mm
                                    </Typography>
                                </div>
                            </Grid>
                        </Hidden>
                        <Hidden mdUp>
                            <Grid item md={9} xs={7} sm={9}>
                                <div className={classes.Note}>
                                    <Typography variant={"subtitle2"}>{props.mousepad.brand}</Typography>
                                    <Typography component="h2" variant="h2">{props.mousepad.name}</Typography>
                                </div>
                            </Grid>
                            <Grid item md={7} xs={12} sm={12} className={classes.NoteInfo}>
                                <Typography align="justify" component="h5"
                                            variant={"body1"}>素材: {props.mousepad.materials}</Typography>
                                <Typography align="justify" component="h5"
                                            variant={"body1"}>スタイル: {props.mousepad.style}</Typography>
                                <Typography align="justify" component="h5" variant={"body1"} className={classes.Size}>
                                    横幅: {props.mousepad.width} mm /
                                    縦幅: {props.mousepad.height} mm
                                </Typography>
                            </Grid>
                        </Hidden>
                        <Grid xs={12} md={12} sm={12} lg className={classes.ButtonGroup} item>
                            {linkButtons()}
                        </Grid>
                    </Grid>
                </Card>
            </ThemeProvider>
        </div>
    );
})

export default MousepadCard;
