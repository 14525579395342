import React from 'react';
import {
    Button,
    makeStyles, Typography, createStyles
} from "@material-ui/core"
import theme from '../../themes/components/linkButton'
import {ThemeProvider} from "@material-ui/core/styles";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            alignItems: "center",
            justifyContent: 'center',

            width: "180px",
            height: "50px",
            lineHeight: "70px",
            borderRadius: "25px",
            boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.35)",
            backgroundColor: "#fff",
            filter: "drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.35))",
            "&:hover": {
                animation: "bg-color 0.1s linear forwards;",
            },
            [theme.breakpoints.down('sm')]: {
                width: "90%",
                height: "36px"
            },
        },
        endIcon: {
            right: "22px",
            position: "absolute",
            [theme.breakpoints.down('sm')]: {
                fontSize: "18px",
            },
        }
    })
);


type LinkButtonProps = {
    label: string;
    link: string;
    className: string;
}

const LinkButton = (props: LinkButtonProps) => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <Button className={[classes.root, props.className].join(" ")} href={props.link}
                    target="_blank">
                <Typography variant={"body1"}> {props.label}</Typography>
                <OpenInNewIcon className={classes.endIcon}/>
            </Button>
        </ThemeProvider>
    );
}

export default LinkButton;
