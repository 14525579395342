import React, {ChangeEvent} from 'react';
import {
    Typography,
    Slider,
    Grid,
    createMuiTheme,
    makeStyles,
    createStyles,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {SliderValue} from "../../definitions/SliderValue";

type RangeSliderProps = {
    label: string;
    minNum: number;
    maxNum: number;
    slideValue: SliderValue;
    handleOnChange: (event: ChangeEvent<{}>, newValue: number | number[]) => void;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        label: {
            "font-size": "20px",
            "font-weight": "bold",
            "text-align": "left",
            [theme.breakpoints.up('md')]: {
                paddingTop: 18,
            },
            [theme.breakpoints.down('sm')]: {
                "text-align": "center",
                fontSize: 16,
            }
        },
    })
);

const defaultTheme = createMuiTheme();

const AdditionalSliderValueLabelStyle = (
    <style>
        {`
            span .MuiSlider-valueLabel {
              margin: 4px 8px;
              transform: rotate(0deg) !important;
            }
                    
            span .MuiSlider-valueLabel > span {
                width: max-content !important;
                height: fit-content !important;
                transform: rotate(0deg) translateX(-35%) translateY(5px) !important;
                border-radius: 5px !important;
            }
    
            span .MuiSlider-valueLabel > span:before {
              content: "";
              position: absolute;
              top: 100%;
              left: 50%;
              margin-left: -4px;
              border: 5px solid transparent;
              border-top: 10px solid #278BC5;
            }
            
            span .MuiSlider-valueLabel > span > span{
                border-radius: 5px !important;
                transform: rotate(0deg) !important;
                padding: 4px 12px;
            }
         `}
    </style>
);

const CustomSlider = withStyles({
    root: {
        margin: "20px 0",
        [defaultTheme.breakpoints.down('sm')]: {
            margin: "12px 0 12px 0",
            padding: "40px 0 0 0",
        }
    },
    rail: {
        height: "4px",
        backgroundColor: "#CCCCCC"
    },
    track: {
        height: "4px",
        backgroundImage: "linear-gradient(.25turn, #28E8FF, #FF3DFE)"
    },
    thumb: {
        height: 20,
        width: 20,
        border: '2px solid none',
        marginTop: -8,
        marginLeft: -12,
        backgroundImage: "linear-gradient(to right top, #28E8FF, #FF3DFE)",
        '&:focus, &:hover': {
            boxShadow: '0px 0px 0px 8px rgba(115, 172, 254, 0.3)',
        },
        [defaultTheme.breakpoints.down('sm')]: {
            height: 16,
            width: 16,
        }
    },
    valueLabel: {
        left: 'calc(-50% + 2px)',
        fontSize: 18,
        top: -52,
        '& *': {
            backgroundColor: "#278BC5",
            color: '#fff',
        },
        [defaultTheme.breakpoints.down('sm')]: {
            left: 'calc(-50%)',
            top: -48,
            fontSize: 14,
        },
    },
})(Slider);

const RangeSlider = (props: RangeSliderProps) => {

    const classes = useStyles();

    return (
        <Grid container>
            <Grid item md={2} xs={12}>
                <Typography id="range-slider" gutterBottom align={"center"} className={classes.label}
                            variant={"body1"}>
                    {props.label}
                </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
                {AdditionalSliderValueLabelStyle}
                <CustomSlider
                    min={props.minNum}
                    max={props.maxNum}
                    defaultValue={Object.values(props.slideValue)}
                    value={Object.values(props.slideValue)}
                    onChange={props.handleOnChange}
                    aria-labelledby="range-slider"
                    valueLabelDisplay="on"
                    step={10}
                />
            </Grid>
        </Grid>
    );
}

export default RangeSlider;
