import {createMuiTheme} from "@material-ui/core";
import {typography} from '../base';

const defaultTheme = createMuiTheme()

const theme = createMuiTheme({
    overrides: {
        MuiTypography: {
            body1: {
                fontSize: "16px",
                color: "#000",
                fontWeight: "bold",
                [defaultTheme.breakpoints.down('sm')]: {
                    fontSize: '14px',
                },
            },
        },
    },
    typography: typography,
});

export default theme;