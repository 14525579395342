import React, {ChangeEvent} from 'react';
import {
    Divider,
    Grid,
    Hidden,
    AccordionSummary,
    Accordion,
    AccordionDetails,
    Typography,
    withStyles
} from "@material-ui/core";
import {SliderValue} from "../../definitions/SliderValue";
import RangeSlider from "../02_molecules/RangeSlider";
import MultiSelectableChips from './MultiSelectableChips';
import {ChipValue} from '../../definitions/ChipValue';
import brandTheme from '../../themes/components/brandChips';
import materialTheme from '../../themes/components/materialChips';
import RadioGroupWithBoth from "./RadioGroupWithBoth";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


type SearchFormProps = {
    materials: string[];
    materialsSelected: string[];
    brands: string[];
    brandsSelected: string[];
    styleSelected: string;
    styles: string[];
    widthMinNum: number;
    widthMaxNum: number;
    heightMinNum: number;
    heightMaxNum: number;
    widthSlider: SliderValue;
    heightSlider: SliderValue;
    handleWidthRangeSliderChange: (event: ChangeEvent<{}>, newValue: number | number[]) => void;
    handleHeightRangeSliderChange: (event: ChangeEvent<{}>, newValue: number | number[]) => void;
    setMaterialsSelected: React.Dispatch<React.SetStateAction<string[]>>;
    setBrandsSelected: React.Dispatch<React.SetStateAction<string[]>>;
    setStyleSelected: React.Dispatch<React.SetStateAction<string>>;
}


const SearchForm = (props: SearchFormProps) => {
    const materialsOption = () => {
        return props.materials.map((m) => {
            return {label: m, value: m, icon: "default"} as ChipValue
        })
    }

    const brandsOption = () => {
        return props.brands.map((m) => {
            return {label: m, value: m, icon: "default"} as ChipValue
        })
    }

    const MuiAccordionSummary = withStyles({
        root: {
            flexDirection: "row",
        },
        content: {
            color: "#fff",
            justifyContent: "center",
            paddingLeft: 48,
        },
        expandIcon: {
            color: "#fff",
        }
    })(AccordionSummary);

    return (
        <form action="">
            <RangeSlider
                minNum={props.widthMinNum}
                maxNum={props.widthMaxNum}
                slideValue={props.widthSlider}
                handleOnChange={props.handleWidthRangeSliderChange}
                label={"横幅"}
            />
            <RangeSlider
                minNum={props.heightMinNum}
                maxNum={props.heightMaxNum}
                slideValue={props.heightSlider}
                handleOnChange={props.handleHeightRangeSliderChange}
                label={"縦幅"}
            />

            <Hidden smDown>
                <RadioGroupWithBoth selectedValue={props.styleSelected}
                                    setSelected={props.setStyleSelected}
                                    values={["ソフト", "ハード"]}
                                    label="スタイル"
                />

                <MultiSelectableChips values={props.brandsSelected}
                                      options={brandsOption()}
                                      setSelected={props.setBrandsSelected}
                                      label={"ブランド"}
                                      theme={brandTheme}
                />

                <MultiSelectableChips values={props.materialsSelected}
                                      options={materialsOption()}
                                      setSelected={props.setMaterialsSelected}
                                      label={"素材"}
                                      theme={materialTheme}
                />
            </Hidden>
            <Hidden mdUp>
                <Accordion
                    style={{marginTop: "36px", marginBottom: "36px", textAlign: "center", borderRadius: "24px", backgroundColor: "rgb(255,255,255,0.15)"}}>
                    <MuiAccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={{fontWeight: "bold"}} align={"center"}>詳細検索</Typography>
                    </MuiAccordionSummary>
                    <Divider/>
                    <AccordionDetails>
                        <Grid container>
                            <RadioGroupWithBoth selectedValue={props.styleSelected}
                                                setSelected={props.setStyleSelected}
                                                values={["ソフト", "ハード"]}
                                                label="スタイル"
                            />

                            <MultiSelectableChips values={props.brandsSelected}
                                                  options={brandsOption()}
                                                  setSelected={props.setBrandsSelected}
                                                  label={"ブランド"}
                                                  theme={brandTheme}
                            />

                            <MultiSelectableChips values={props.materialsSelected}
                                                  options={materialsOption()}
                                                  setSelected={props.setMaterialsSelected}
                                                  label={"素材"}
                                                  theme={materialTheme}
                            />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Hidden>
        </form>
    );
}

export default SearchForm;
