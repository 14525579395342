import React from 'react';
import {Mousepad} from "../../definitions/Mousepad";
import MousepadCard from "../02_molecules/MousepadCard";
import {AutoSizer} from "react-virtualized";

const AnimationButtonStyle = (
    <style>
        {`
        @-webkit-keyframes bg-color {
            0% { background: #fff; opacity: 1; }
            10% { background: linear-gradient(to right, #C0F5FA, #FFEAFF 100%); opacity: 1; }
            30% { background: linear-gradient(to right, #A3F8FF, #FDD6FD 100%); opacity: 1; }
            40% { background: linear-gradient(to right, #86F5FF, #FCBBFB 100%); opacity: 1; }
            50% { background: linear-gradient(to right, #75F2FD, #FD9EFD 100%); opacity: 1; }
            70% { background: linear-gradient(to right, #62F2FF, #FC88FB 100%); opacity: 1; }
            80% { background: linear-gradient(to right, #4DF0FF, #FD6AFD 100%); opacity: 1; }
            90% { background: linear-gradient(to right, #39EFFF, #FD59FC 100%); opacity: 1;}
            100% { background: linear-gradient(to right, #22EDFF, #FF3DFE 100%); opacity: 1; }
        }

        @-moz-keyframes bg-color {
            0% { background: #fff; opacity: 1; }
            10% { background: linear-gradient(to right, #C0F5FA, #FFEAFF 100%); opacity: 1; }
            30% { background: linear-gradient(to right, #A3F8FF, #FDD6FD 100%); opacity: 1; }
            40% { background: linear-gradient(to right, #86F5FF, #FCBBFB 100%); opacity: 1; }
            50% { background: linear-gradient(to right, #75F2FD, #FD9EFD 100%); opacity: 1; }
            70% { background: linear-gradient(to right, #62F2FF, #FC88FB 100%); opacity: 1; }
            80% { background: linear-gradient(to right, #4DF0FF, #FD6AFD 100%); opacity: 1; }
            90% { background: linear-gradient(to right, #39EFFF, #FD59FC 100%); opacity: 1;}
            100% { background: linear-gradient(to right, #22EDFF, #FF3DFE 100%); opacity: 1; }
        }
    
        @keyframes bg-color {
            0% { background: #fff; opacity: 1; }
            10% { background: linear-gradient(to right, #C0F5FA, #FFEAFF 100%); opacity: 1; }
            30% { background: linear-gradient(to right, #A3F8FF, #FDD6FD 100%); opacity: 1; }
            40% { background: linear-gradient(to right, #86F5FF, #FCBBFB 100%); opacity: 1; }
            50% { background: linear-gradient(to right, #75F2FD, #FD9EFD 100%); opacity: 1; }
            70% { background: linear-gradient(to right, #62F2FF, #FC88FB 100%); opacity: 1; }
            80% { background: linear-gradient(to right, #4DF0FF, #FD6AFD 100%); opacity: 1; }
            90% { background: linear-gradient(to right, #39EFFF, #FD59FC 100%); opacity: 1;}
            100% { background: linear-gradient(to right, #22EDFF, #FF3DFE 100%); opacity: 1; }
        }
        `}
    </style>
)

type mousepadsProps = {
    mousepads: Mousepad[];
    additionalClass: string;
}
const MousepadCards = (props: mousepadsProps) => {

    const getMousepads = (width: number) => {
        return props.mousepads.map((p) => {
            return (
                <MousepadCard width={width} key={p.key} className={props.additionalClass} mousepad={p}/>
            )
        });
    }

    return (
        <div>
            {AnimationButtonStyle}
            <AutoSizer disableHeight>
                {({width}) => getMousepads(width)}
            </AutoSizer>
        </div>
    )
}

export default MousepadCards;
