import React, {useState, useEffect, ChangeEvent} from 'react';

import {Box, Container, Grid, makeStyles, Typography, createStyles} from "@material-ui/core";
import {Mousepad} from "./definitions/Mousepad";
import SearchForm from "./components/03_organisms/SearchForm";
import {SliderValue} from "./definitions/SliderValue";
import MousepadCards from "./components/03_organisms/MousepadCards";

import {
    DEFAULT_BEGIN_NUM as WIDTH_DEFAULT_BEGIN_NUM,
    DEFAULT_END_NUM as WIDTH_DEFAULT_END_NUM
} from "./definitions/WidthSliderValue";
import {
    DEFAULT_BEGIN_NUM as HEIGHT_DEFAULT_BEGIN_NUM,
    DEFAULT_END_NUM as HEIGHT_DEFAULT_END_NUM
} from "./definitions/HeightSliderValue";

import Data from "./mock/mousepads.json";
import RGBSwitcher from "./components/02_molecules/RBGSwithcer";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: "18px 88px",
            [theme.breakpoints.down('sm')]: {
                padding: "16px 24px",
            },
            borderRadius: "5px",

            color: "#FFFFFF"
        },
        logo: {
            text_align: "center",
            padding: "88px 0px",
            [theme.breakpoints.down('sm')]: {
              padding: "12px 0"
            },
        },
        logImg: {
            [theme.breakpoints.down('sm')]: {
                width: "auto",
                height: 44
            },
            width: "auto",
            height: 121
        },
        Content: {
            [theme.breakpoints.up('md')]: {
                padding: '0 0 0 30px',
            },
            margin: "20px 0 12px 0",
        },
        MousepadCards: {
            margin: "0px 0 12px 0",
        },
        MousepadCard: {
            margin: "56px 0",
            [theme.breakpoints.down('md')]: {
                margin: "28px 0",
            },
        },
        ResultNumber: {
            [theme.breakpoints.down('sm')]: {
              fontSize: "16px"
            },
            display: "inline",
            fontSize: "36px"
        },
        ResultText: {
            [theme.breakpoints.down('sm')]: {
                fontSize: "12px"
            },
            display: "inline",
            fontSize: "20px"
        },
        resultGrid: {
            [theme.breakpoints.down('sm')]: {
                textAlign: "center",
                padding: "2px !important"
            },
        }
    })
);

const App = () => {

    const classes = useStyles();

    const MOUSEPAD_MATERIALS = ["クロス", "ゴム", "ポリプロピレン", "ソフトファブリック", "ポリエチレン", "樹脂", "ポリカーボネート", "プラスチック"];
    const MOUSEPAD_BRANDS = ["HyperX", "amazonベーシック", "Logicool", "Havit", "SteelSeries", "VicTsing", "Hiveseen", "RAZER", "Corsair", "ROCCAT", "Xtrfy", "ASUS", "MSI", "Cooler Master"];

    const [materialsSelected, setMaterialsSelected] = useState(MOUSEPAD_MATERIALS as string[]);
    const [brandsSelected, setBrandsSelected] = useState(MOUSEPAD_BRANDS as string[]);
    const [styleSelected, setStyleSelected] = useState("どちらも" as string);

    const [isRGB, setIsRGB] = useState(false as boolean);

    const [widthSlider, setWidthSlider] = useState({
        begin: WIDTH_DEFAULT_BEGIN_NUM,
        end: WIDTH_DEFAULT_END_NUM
    } as SliderValue);

    const [heightSlider, setHeightSlider] = useState({
        begin: HEIGHT_DEFAULT_BEGIN_NUM,
        end: HEIGHT_DEFAULT_END_NUM
    } as SliderValue);

    const handleWidthRangeSliderChange = (event: ChangeEvent<{}>, newValue: number | number[]) => {
        if (newValue instanceof Array && newValue.length === 2) {
            setWidthSlider({begin: newValue[0], end: newValue[1]})
        }
    };

    const handleHeightRangeSliderChange = (event: ChangeEvent<{}>, newValue: number | number[]) => {
        if (newValue instanceof Array && newValue.length === 2) {
            setHeightSlider({begin: newValue[0], end: newValue[1]})
        }
    };

    const handleRGB = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const isRGB = checked;
        setIsRGB(isRGB);
    }

    const [mousepads, setMousepads] = useState([] as Mousepad[]);

    useEffect(() => {
        setMousepads(Data);
    }, []);

    useEffect(() => {
        setMousepads(Data.filter(p =>
            p.width >= widthSlider.begin && p.width < widthSlider.end
            && p.height >= heightSlider.begin && p.height < heightSlider.end
            && materialsSelected.some((m) => {
                return p.materials.includes(m)
            })
            && brandsSelected.includes(p.brand)
            && (isRGB === false || isRGB === p.rgb)
            && (styleSelected === "どちらも" || styleSelected === p.style)
        ));
    }, [widthSlider, heightSlider, materialsSelected, isRGB, brandsSelected, styleSelected])

    const MOUSEPAD_WIDTH_MAX = 1320;
    const MOUSEPAD_WIDTH_MIN = 150;
    const MOUSEPAD_HEIGHT_MAX = 660;
    const MOUSEPAD_HEIGHT_MIN = 140;

    const globalStyle = (
        <style>
            {`
                @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100&display=swap');
                body { background: linear-gradient(to bottom right, #2A3292 0%, #10143c 100%)}
            `}
        </style>
    )

    return (
        <Container className={classes.root}>
            {globalStyle}
            <Box className={classes.logo} textAlign={"center"}>
                <img className={classes.logImg} width="200" height="55" alt="logo" src={require("./images/shared/LOGO.png").default}/>
            </Box>

            <Box className={classes.Content}>

                <SearchForm
                    widthSlider={widthSlider}
                    heightSlider={heightSlider}
                    heightMaxNum={MOUSEPAD_HEIGHT_MAX}
                    heightMinNum={MOUSEPAD_HEIGHT_MIN}
                    widthMinNum={MOUSEPAD_WIDTH_MIN}
                    widthMaxNum={MOUSEPAD_WIDTH_MAX}
                    handleWidthRangeSliderChange={handleWidthRangeSliderChange}
                    handleHeightRangeSliderChange={handleHeightRangeSliderChange}
                    materialsSelected={materialsSelected}
                    materials={MOUSEPAD_MATERIALS}
                    setMaterialsSelected={setMaterialsSelected}
                    brandsSelected={brandsSelected}
                    brands={MOUSEPAD_BRANDS}
                    setBrandsSelected={setBrandsSelected}
                    styleSelected={styleSelected}
                    styles={["ソフト", "ハード"]}
                    setStyleSelected={setStyleSelected}
                />
            </Box>
            <Box className={classes.Content}>
                <Grid container spacing={0}>
                    <Grid item md={4} xs={12} className={classes.resultGrid}>
                        <Typography className={classes.ResultNumber} variant={"body1"}> {mousepads.length} </Typography><Typography
                        className={classes.ResultText} variant={"body2"}>件見つかりました！</Typography>
                    </Grid>
                    <Grid item md={8} xs={12} className={classes.resultGrid}>
                        <RGBSwitcher label="RGB LED搭載のみを表示する" isRGB={isRGB} handleRGB={handleRGB}/>
                    </Grid>
                </Grid>
            </Box>
            <Box className={[classes.Content, classes.MousepadCards].join(" ")}>
                <MousepadCards mousepads={mousepads} additionalClass={classes.MousepadCard}></MousepadCards>
            </Box>
        </Container>
    )
}

export default App;
