import {createMuiTheme} from "@material-ui/core";
import {typography} from '../base';

const theme = createMuiTheme({
    overrides: {
        MuiChip: {
            outlinedPrimary: {
                border: "1px solid #22edff",
                color: "#22edff",
            },
            colorPrimary: {
                backgroundColor: "#22edff",
                color: "black",
            }
        },
    },
    typography: typography,
});

export default theme;