import {createMuiTheme} from "@material-ui/core";
import {typography} from '../base';

const defaultTheme = createMuiTheme()

const theme = createMuiTheme({
    overrides: {
        MuiTypography: {
            subtitle2: {
                fontSize: "16px",
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: '14px'
                },
                [defaultTheme.breakpoints.only('sm')]: {
                    fontSize: '16px',
                    margin: "0 0 8px 0",
                },
                color: "#22edff",
                margin: "0 0 4px 0",
            },
            h2: {
                fontWeight: "bold",
                fontSize: "28px",
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: '16px',
                    margin: "0 0 8px 0",
                },
                [defaultTheme.breakpoints.only('sm')]: {
                    fontSize: '24px',
                    margin: "0 0 8px 0",
                },
                wordWrap: "break-word",
                margin: "0 0 20px 0",
                color: "#FFFFFF",
            },
            body1: {
                fontSize: "16px",
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: '14px',
                    margin: "0 0 2px 0"
                },
                [defaultTheme.breakpoints.only('sm')]: {
                    fontSize: '14px',
                    margin: "0 0 8px 0",
                },
                margin: "0 0 12px 0",
                color: "#FFFFFF",
            },
        },
    },
    typography: typography,
});

export default theme;