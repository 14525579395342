import React from 'react';
import {
    makeStyles,
    Grid,
    Typography,
    Chip,
    MuiThemeProvider, Theme, createStyles
} from "@material-ui/core"
import {ChipValue} from '../../definitions/ChipValue';

type MultiSelectableChipsProps = {
    label: string;
    values: string[];
    options: ChipValue[];
    setSelected: React.Dispatch<React.SetStateAction<string[]>>;
    theme: Theme;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginBottom: 16,
            [theme.breakpoints.down('sm')]: {
                marginTop: "8px",
                textAlign: "center",
                justifyItems: "center",
            }
        },
        grid: {
            padding: "4px !important",
            margin: "4px",
        },
        label: {
            "font-size": "20px",
            "font-weight": "bold",
            "text-align": "left",
            paddingLeft: 8,
            [theme.breakpoints.down('sm')]: {
                "color": "#fff",
                textAlign: "center",
                fontSize: "16px",
            },
        },
        chip: {
            height: "31px",
            "box-shadow": "0px 1px 3px rgba(0, 0, 0, 0.25)",
            "border-radius": "4px",
            "margin": "8px 8px",
            [theme.breakpoints.down('sm')]: {
                margin: "4px"
            }
        },
        chipLabel: {
            lineHeight: 1.2,
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 2,
                fontSize: "12px",
            }
        }
    })
);

const MultiSelectableChips = (props: MultiSelectableChipsProps) => {
    const classes = useStyles();

    const handleOnClick = (value: string) => {
        if (props.values.find((e) => e === value)) {
            const index = props.values.findIndex((e) => e === value);
            let arr = [...props.values];
            arr.splice(index, 1);
            props.setSelected(arr);
        } else {
            props.setSelected([...props.values, value]);
        }
    }

    const renderChips = () => {
        return props.options.map((option, i) => {
            return <Chip
                key={i}
                className={classes.chip}
                color="primary"
                variant={
                    props.values.find((e) => e === option.value)
                        ? "default"
                        : "outlined"
                }
                label={
                    <Typography variant="body1" className={classes.chipLabel}>{`${option.label}`}</Typography>
                }
                onClick={() => handleOnClick(option.value)}
            />
        })
    }

    return (
        <MuiThemeProvider theme={props.theme}>
            <Grid container spacing={4} className={classes.root}>
                <Grid item md={2} xs={12} className={classes.grid}>
                    <Typography className={classes.label}>{props.label}</Typography>
                </Grid>
                <Grid item md={10} xs={12} className={classes.grid}>
                    {renderChips()}
                </Grid>
            </Grid>
        </MuiThemeProvider>
    );
}

export default MultiSelectableChips;
